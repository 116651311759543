import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Cubes,
  HalfWidthBlock,
  Media,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import Top from "../../../images/reference/doktor-kladivo/top.png"
import Wireframe from "../../../images/reference/doktor-kladivo/wireframe1.png"
import Mid from "../../../images/reference/doktor-kladivo/timeline.png"
import Design from "../../../images/reference/doktor-kladivo/design.png"
import MobileScreen from "../../../images/reference/doktor-kladivo/mobile-screen.png"
import DesktopScreen from "../../../images/reference/doktor-kladivo/desktop-screen.png"

class DoktorKladivo extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Nový e-shop pro Doktor Kladivo | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového e-shopu pro doktorkladivo.cz, specialistu na nářadí."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//doktorkladivo.cz/"
          heading="B2B databáze, e-shop a mikro web"
          odstavec="srpen 2017 - červenec 2021"
        />

        <ReferenceContainer>
          <Media
            iPhone={{
              backgroundSrc: MobileScreen,
              backgroundAlt: "",
            }}
            laptop={{
              backgroundSrc: DesktopScreen,
              backgroundAlt: "",
            }}
          />

          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Průběh spolupráce</TitleH2>
              <p>
                Naším úkolem bylo vytvořit kompletní vizuální identitu pro novou
                značku <b>Doktor Kladivo</b>. Celý projekt teprve začínal, takže
                bylo potřeba dodat <b>grafiku</b> a nasadit ji na <b>e-shop</b>,
                vymyslet jednoduchý způsob pro import dat od dodavatelů a vše
                synchronizovat s ekonomickým systémem <b>Pohoda</b>.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <ul>
              <li>
                Na brandovém workshopu jsme definovali nově vzniklou značku
                Doktor Kladivo a vytvořili jí <b>vizuální identitu</b>.
              </li>
              <li>
                Naše e-shopy stojí na pevných <b>datech</b>. Z provedených
                analýz jsme získali datové podklady pro tvorbu <b>person</b>,{" "}
                <b>wireframů</b> a v podstatě celého internetového obchodu.
              </li>
              <li>
                Grafika čekalo několik perných dnů. S úkolem vytvořit{" "}
                <b>grafické</b> podklady pro celou značku a e-shop si ale
                poradil skvěle.
              </li>
              <li>
                Nedočkaví ajťáci mohli s hotovou grafikou nakódovat webové{" "}
                <b>šablony</b>a nasadit celý systém. Přidali možnost{" "}
                <b>importovat data</b> od dodavatelů a všechno propojili s{" "}
                <b>Pohodou</b>– to je u našich e-shopů už standard.
              </li>
            </ul>
          </PaddingWrapper>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Wireframe}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Analýza a wireframy</TitleH2>

              <p>
                Klient nám opatrně naznačil, že web bude mít tisíce produktů,
                které je třeba <b>správně roztřídit</b>.
              </p>
              <p>
                Nic jsme tedy nepodcenili a provedli <b>analýzy</b>, které nám
                pomohly prolomit ledy v našich dilematech a nasměrovaly nás na
                správnou cestu.
              </p>
              <Cubes
                data={[
                  {
                    description: "Kategorií produktů",
                    number: "400+",
                  },
                  {
                    description: "Vytvořené persony",
                    number: "4",
                  },
                  {
                    description: "Typových podstránek",
                    number: "36",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>
        </ReferenceContainer>

        <img
          src={Mid}
          alt=""
          className="img-fluid d-none d-md-flex m-auto"
          loading="lazy"
        />

        <img
          src={Top}
          alt=""
          className="img-fluid d-flex d-md-none m-auto"
          loading="lazy"
        />

        <ReferenceContainer>
          <BlockImageTextHalf
            className="d-md-none"
            image={Design}
            alt={"ALT"}
            right
            hideImageOnSmall
          >
            <TitleH2>Design</TitleH2>

            <p>
              V igloo máme rádi vyvážení vizuálně <b>atraktivního designu</b> s
              uživatelskou přívětivostí.
            </p>
            <p>
              Grafika musí návštěvníka zaujmout neotřelým designem, ale nemůžeme
              zapomenout na <b>UX</b> a důvody, proč vlastně na stránky přišel.
            </p>
          </BlockImageTextHalf>

          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Shrnutí webu</TitleH2>
              <p>
                Práce na projektu Doktor Kladivo byla <b>radost</b>. Klient má
                velké zkušenosti s provozováním různých internetových obchodů, a
                tak přesně <b>věděl, co chce</b> a potřebuje. Odpadla tím
                spousta administrativy, a my jsme se mohli zaměřit čistě na{" "}
                <b>realizaci</b>. Při návrzích řešení jsme měli velikou volnost,
                ať už se jednalo o přípravné práce a výrobu designu nebo o
                samotnou implementaci.
              </p>
              <p>
                Naše zapojení do projektu rozhodně <b>neskončilo spuštěním</b>{" "}
                e-shopu. Průběžně vyvíjíme a <b>přidáváme</b> další funkce,
                např. pro jednodušší administraci nebo na podporu obchodní
                činnosti společnosti. Kolegové z <b>hostingového</b> oddělení
                chystají optimalizace chodu virtuálního <b>serveru</b>, na
                kterém e-shop pro Doktor Kladivo běží. Na <b>marketingu</b> jsme
                se pustili do vyladění zbožových <b>srovnávačů</b> s ohledem na
                četnost a rozmanitost produků e-shopu.
              </p>
              <p>
                V červenci 2021 jsme web předali ke správě internímu týmu
                e-shopu.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default DoktorKladivo
